<template>
  <div v-if="!$store.state.mobile" style="background-color: #faf9f9">
    <HeaderComponent></HeaderComponent>
    <v-row class="mx-0 justify-center" style="width: 100%;">
      <v-col class="d-flex" cols="10">
        <div style="height: 100% ;width: 12px; background-color: #01009a">

        </div>
        <h2 class="ml-2">
          ÜBER UNS
        </h2>
      </v-col>
    </v-row>
    <div class="grey mt-3" style="height: 500px;">
      <v-row class="mx-0 justify-center align-center" style="width: 100%; height: 100%">
        <v-col cols="5">
          <v-img cover src="../assets/uberUns.jpg" style="height: 450px; "></v-img>
        </v-col>
        <v-col cols="5">
          <h3>Herzlich willkommen bei der BG: Bürgergemeinschaft Bad-Sassendorf</h3>
          <br>
          <br>
          <p class="font-weight-light" style="font-size: 16px">
            Wir sind die Bürgergemeinschaft Bad Sassendorf, eine engagierte Gruppe von Bürgerinnen und Bürgern,
            die sich aktiv für die Belange und die Weiterentwicklung unserer schönen Gemeinde einsetzen.
            <br> <br>
            Unser Ziel ist es, das Miteinander zu fördern, Projekte voranzutreiben und die Lebensqualität in Bad
            Sassendorf zu verbessern.
            Gemeinsam organisieren wir Veranstaltungen, unterstützen lokale Initiativen und setzen uns für eine
            nachhaltige und lebenswerte Zukunft ein.
            <br> <br>
            Wir laden alle ein, Teil unserer Gemeinschaft zu werden und gemeinsam an der positiven Gestaltung unseres
            Ortes mitzuwirken.
          </p>
        </v-col>
      </v-row>
    </div>
    <div class="white py-4">
      <v-row class="ma-0" style="width: 100%">
        <v-col cols="3">
          <div
              style="width: 100%; height: 700px; padding:20px; background-image: linear-gradient(to top, #000099 40%, #0909cd 70%); box-shadow: 2px 2px 5px rgba(0,0,0,0.81)">
            <v-img v-if="loading" :src="vorsitzender?.bild" style="height: 450px"></v-img>
            <h2 class="text-white text-center mt-4">{{
                vorsitzender?.geschlecht === 'm' ? "Vorsitzender" : "Vorsitzende"
              }}</h2>
            <h3 class="text-white text-center mt-1">{{ vorsitzender?.vorname }} {{ vorsitzender?.name }}</h3>
            <p class="text-white text-center mt-1">
              <a class="text-white" href="mailto:vorsitzender@bg-badsassendorf.de">
                vorsitzender@bg-badsassendorf.de
              </a>
            </p>
            <p class="text-white text-center mt-1">
              {{ vorsitzender?.adresse }}
            </p>
            <p class="text-white text-center mt-1">
              {{ vorsitzender?.plz }} {{ vorsitzender?.stadt }}
            </p>
          </div>
        </v-col>
        <v-col cols="3">
          <div
              style="width: 100%; height: 700px; padding:20px; background-image: linear-gradient(to top, #000099 40%, #0909cd 70%); box-shadow: 2px 2px 5px rgba(0,0,0,0.81)">
            <v-img v-if="loading" :src="stellvVorsitzender?.bild" style="height: 450px"></v-img>
            <h2 class="text-white text-center mt-4">
              {{
                stellvVorsitzender?.geschlecht === 'm' ? "Stellvertretender Vorsitzender" : "Stellvertretende Vorsitzende"
              }}</h2>
            <h3 class="text-white text-center mt-1">{{ stellvVorsitzender?.vorname }}
              {{ stellvVorsitzender?.name }}</h3>
            <p class="text-white text-center mt-1">
              <a class="text-white" href="mailto:stellvVorsitzender@bg-badsassendorf.de">
                stellvVorsitzender@bg-badsassendorf.de
              </a>
            </p>
            <p class="text-white text-center mt-1">
              {{ stellvVorsitzender?.adresse }}
            </p>
            <p class="text-white text-center mt-1">
              {{ stellvVorsitzender?.plz }} {{ stellvVorsitzender?.stadt }}
            </p>
          </div>
        </v-col>
        <v-col cols="3">
          <div
              style="width: 100%; height: 700px; padding:20px; background-image: linear-gradient(to top, #000099 40%, #0909cd 70%); box-shadow: 2px 2px 5px rgba(0,0,0,0.81)">
            <v-img v-if="loading" :src="schriftführer?.bild" style="height: 450px"></v-img>
            <h2 class="text-white text-center mt-4">
              {{ schriftführer?.geschlecht === 'm' ? "Schriftführer" : "Schriftführerin" }}</h2>
            <h3 class="text-white text-center mt-1">{{ schriftführer?.vorname }} {{ schriftführer?.name }}</h3>
            <p class="text-white text-center mt-1">
              <a class="text-white" href="mailto:Schriftfuehrer@bg-badsassendorf.de">
                Schriftfuehrer@bg-badsassendorf.de
              </a>
            </p>
            <p class="text-white text-center mt-1">
              {{ schriftführer?.adresse }}
            </p>
            <p class="text-white text-center mt-1">
              {{ schriftführer?.plz }} {{ schriftführer?.stadt }}
            </p>
          </div>
        </v-col>
        <v-col cols="3">
          <div
              style="width: 100%; height: 700px; padding:20px; background-image: linear-gradient(to top, #000099 40%, #0909cd 70%); box-shadow: 2px 2px 5px rgba(0,0,0,0.81)">
            <v-img v-if="loading" :src="schatzmeister?.bild" style="height: 450px"></v-img>
            <h2 class="text-white text-center mt-4">
              {{ schatzmeister?.geschlecht === 'm' ? "Schatzmeister" : "Schatzmeisterin" }}</h2>
            <h3 class="text-white text-center mt-1">{{ schatzmeister?.vorname }} {{ schatzmeister?.name }}</h3>
            <p class="text-white text-center mt-1">
              <a class="text-white" href="mailto:Kassierer@bg-badsassendorf.de">
                kassierer@bg-badsassendorf.de
              </a>
            </p>
            <p class="text-white text-center mt-1">
              {{ schatzmeister?.adresse }}
            </p>
            <p class="text-white text-center mt-1">
              {{ schatzmeister?.plz }} {{ schatzmeister?.stadt }}
            </p>
          </div>
        </v-col>
      </v-row>
    </div>
    <FooterComponent></FooterComponent>
  </div>
  <div v-if="$store.state.mobile" style="height: 100vh;">
    <v-layout>

      <v-navigation-drawer
          v-model="$store.state.drawer"
          location="right"
          style="background-image: linear-gradient(to top, #000099 10%, #0909cd 70%);color: white"
          temporary
      >
        <DrawerComponent></DrawerComponent>
      </v-navigation-drawer>
      <v-main style="height: 100%">
        <HeaderComponent></HeaderComponent>
        <v-row class="mx-0 pb-10 justify-center align-center" style="width: 100%; height: 100%">
          <v-col cols="12">
            <v-img src="../assets/uberUns.jpg" style="height: 450px; "></v-img>
          </v-col>
          <v-col cols="12">
            <h3>Herzlich willkommen bei der BG: Bürgergemeinschaft Bad-Sassendorf</h3>
            <br>
            <br>
            <p class="font-weight-light" style="font-size: 16px">
              Wir sind die Bürgergemeinschaft Bad Sassendorf, eine engagierte Gruppe von Bürgerinnen und Bürgern,
              die sich aktiv für die Belange und die Weiterentwicklung unserer schönen Gemeinde einsetzen.
              <br> <br>
              Unser Ziel ist es, das Miteinander zu fördern, Projekte voranzutreiben und die Lebensqualität in Bad
              Sassendorf zu verbessern.
              Gemeinsam organisieren wir Veranstaltungen, unterstützen lokale Initiativen und setzen uns für eine
              nachhaltige und lebenswerte Zukunft ein.
              <br> <br>
              Wir laden alle ein, Teil unserer Gemeinschaft zu werden und gemeinsam an der positiven Gestaltung unseres
              Ortes mitzuwirken.
            </p>
          </v-col>
        </v-row>
        <v-row class="ma-0 pb-10 justify-center" style="width: 100%">
          <v-col cols="11">
            <div
                style="width: 100%; height: 700px; padding:20px; background-image: linear-gradient(to top, #000099 40%, #0909cd 70%); box-shadow: 2px 2px 5px rgba(0,0,0,0.81)">
              <v-img v-if="loading" :src="vorsitzender?.bild" style="height: 450px"></v-img>
              <h2 class="text-white text-center mt-4">
                {{ vorsitzender?.geschlecht === 'm' ? "Vorsitzender" : "Vorsitzende" }}</h2>
              <h3 class="text-white text-center mt-1">{{ vorsitzender?.vorname }} {{ vorsitzender?.name }}</h3>
              <p class="text-white text-center mt-1">
                <a class="text-white" href="mailto:vorsitzender@bg-badsassendorf.de">
                  vorsitzender@bg-badsassendorf.de
                </a>
              </p>
              <p class="text-white text-center mt-1">
                {{ vorsitzender?.adresse }}
              </p>
              <p class="text-white text-center mt-1">
                {{ vorsitzender?.plz }} {{ vorsitzender?.stadt }}
              </p>
            </div>
          </v-col>
          <v-col cols="11">
            <div
                style="width: 100%; height: 700px; padding:20px; background-image: linear-gradient(to top, #000099 40%, #0909cd 70%); box-shadow: 2px 2px 5px rgba(0,0,0,0.81)">
              <v-img v-if="loading" :src="stellvVorsitzender?.bild" style="height: 450px"></v-img>
              <h2 class="text-white text-center mt-4">
                {{
                  stellvVorsitzender?.geschlecht === 'm' ? "Stellvertretender Vorsitzender" : "Stellvertretende Vorsitzende"
                }}</h2>
              <h3 class="text-white text-center mt-1">{{ stellvVorsitzender?.vorname }}
                {{ stellvVorsitzender?.name }}</h3>
              <p class="text-white text-center mt-1">
                <a class="text-white" href="mailto:stellvVorsitzender@bg-badsassendorf.de">
                  stellvVorsitzender@bg-badsassendorf.de
                </a>
              </p>
              <p class="text-white text-center mt-1">
                {{ stellvVorsitzender?.adresse }}
              </p>
              <p class="text-white text-center mt-1">
                {{ stellvVorsitzender?.plz }} {{ stellvVorsitzender?.stadt }}
              </p>
            </div>
          </v-col>
          <v-col cols="11">
            <div
                style="width: 100%; height: 700px; padding:20px; background-image: linear-gradient(to top, #000099 40%, #0909cd 70%); box-shadow: 2px 2px 5px rgba(0,0,0,0.81)">
              <v-img v-if="loading" :src="schriftführer?.bild" style="height: 450px"></v-img>
              <h2 class="text-white text-center mt-4">
                {{ schriftführer?.geschlecht === 'm' ? "Schriftführer" : "Schriftführerin" }}</h2>
              <h3 class="text-white text-center mt-1">{{ schriftführer?.vorname }} {{ schriftführer?.name }}</h3>
              <p class="text-white text-center mt-1">
                <a class="text-white" href="mailto:Schriftfuehrer@bg-badsassendorf.de">
                  Schriftfuehrer@bg-badsassendorf.de
                </a>
              </p>
              <p class="text-white text-center mt-1">
                {{ schriftführer?.adresse }}
              </p>
              <p class="text-white text-center mt-1">
                {{ schriftführer?.plz }} {{ schriftführer?.stadt }}
              </p>
            </div>
          </v-col>
          <v-col cols="11">
            <div
                style="width: 100%; height: 700px; padding:20px; background-image: linear-gradient(to top, #000099 40%, #0909cd 70%); box-shadow: 2px 2px 5px rgba(0,0,0,0.81)">
              <v-img v-if="loading" :src="schatzmeister?.bild" style="height: 450px"></v-img>
              <h2 class="text-white text-center mt-4">
                {{ schatzmeister?.geschlecht === 'm' ? "Schatzmeister" : "Schatzmeisterin" }}</h2>
              <h3 class="text-white text-center mt-1">{{ schatzmeister?.vorname }} {{ schatzmeister?.name }}</h3>
              <p class="text-white text-center mt-1">
                <a class="text-white" href="mailto:Kassierer@bg-badsassendorf.de">
                  kassierer@bg-badsassendorf.de
                </a>
              </p>
              <p class="text-white text-center mt-1">
                {{ schatzmeister?.adresse }}
              </p>
              <p class="text-white text-center mt-1">
                {{ schatzmeister?.plz }} {{ schatzmeister?.stadt }}
              </p>
            </div>
          </v-col>
        </v-row>
        <FooterComponent></FooterComponent>
      </v-main>
    </v-layout>


  </div>
</template>
<script>
import HeaderComponent from "@/components/HeaderComponent.vue";
import FooterComponent from "@/components/FooterComponent.vue";
import axios from "axios";
import DrawerComponent from "@/components/DrawerComponent.vue";

export default {
  data() {
    return {
      loading: false,
      vorsitzender: null,
      stellvVorsitzender: null,
      schriftführer: null,
      schatzmeister: null
    }
  },
  methods: {
    async getVorsitzender() {
      let response = await axios.get('vorsitzender/1')
      this.vorsitzender = response.data
      console.log(response.data)
    },
    async getStellvVorsitzender() {
      let response = await axios.get('StellvertretenderVorsitzender/1')
      this.stellvVorsitzender = response.data
      console.log(response.data)
    },
    async getSchriftführer() {
      let response = await axios.get('schriftfuehrer/1')
      this.schriftführer = response.data
      console.log(response.data)
    }, async getSchatzmeister() {
      let response = await axios.get('schatzmeister/1')
      this.schatzmeister = response.data
      console.log(response.data)
    },
  },
  async mounted() {
    this.loading = false
    await this.getVorsitzender()
    await this.getStellvVorsitzender()
    await this.getSchriftführer()
    await this.getSchatzmeister()
    this.loading = true

  },
  components: {DrawerComponent, FooterComponent, HeaderComponent},

}
</script>

<style>
.white {
  background-color: #faf9f9;
}

</style>
